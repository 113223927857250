import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import TrbmContent from "src/components/modules/trbmcontent"
import CtaDisclosure from "@tightrope/ctadisclosure"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"

const prdbestData = import("./data/data.json");

const Img = () => <img className="cws" src="/img/cws-badge.png" />;

export default function prdbest() {


    let module;
     module = null;
    if(isChrome() || isIeEdge()){
       module = <CtaDisclosureSearch  data={prdbestData}></CtaDisclosureSearch>
     }
     if(isFirefox()){
       module = <CtaDisclosure data={prdbestData}></CtaDisclosure>
     }

     let css;
       if(isFirefox()){
         css = `.trbm-module--trbm .trbm-module--bullets {
           padding-left: 155px;
         }
         .cws {
           display: none;
         }
           .trbm-module--trbm button {
             padding: 20px 100px!important;
             font-weight: bold;
           }
         #ctadisclosure-module--ctadisclaimer {
             padding: 25px 7% 0;
           }
         }`;
       }
       else{
         css = `.trbm-module--trbm .trbm-module--bullets {
           padding-left: 0;
         }
         .cws {
           width: 200px;
           display: block;
           margin: 10px auto;
         }
         #ctadisclosure-module--ctadisclaimer {
           padding: 0px 7%;
           padding-bottom: 0;

         }`;
       }

  return(
    <HomepageLayout>
      <Helmet>
<style type="text/css">{`
h1 {
  width: 100%;
}
body {
background-image: url(/img/template-index-bg.jpg);
background-color: #ccc;
background-repeat: no-repeat;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
}

button.trbm_cta {
  font-weight: normal;
  font-size: 30px;
}

#ctadisclosuresearch-module--ctadisclaimersearch  {
  padding: 0px 7%;
  padding-bottom: 0;
}

.trbm-module--card {
border: 1px solid #727272;
}

.trbm-module--bottom { display: none;}
.footerlinks-module--br, .footerlinks-module--br a {
  color: #bdbdbd;
}
`}
   </style>

   <style type="text/css">
     {css}
         </style>

      <title>Freshy Search - freshysearch.com</title></Helmet>
      <TrbmContent data={prdbestData}><Img />{module}</TrbmContent>
    </HomepageLayout>
  )
}
